<template>
  <el-dialog :title="dataForm.id ? '编辑选项值' : '添加选项值'" :close-on-click-modal="false" :visible.sync="dialogVisible" width="500px">
    <div>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
        <el-form-item label="字段名" prop="type">
          <el-select v-model="dataForm.type" placeholder="请选择字段名" style="width: 90%;">
            <el-option v-for="item in stringList" :key="item.value" :value="item.name" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级信息" prop="secType" v-if="dataForm.type == '二级课程'">
          <el-select v-model="dataForm.secType" placeholder="请选择上级信息" style="width: 90%;">
            <el-option v-for="item in projectList" :key="item.value" :value="item.name" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选项值" prop="optionValue">
          <el-input v-model="dataForm.optionValue" placeholder="请输入选项值" style="width: 90%;"></el-input>
        </el-form-item>
				<el-form-item label="课程介绍"  v-if="dataForm.type == '二级课程'">
				  <el-input v-model="dataForm.ename" placeholder="请输入课程介绍,最多50字"  maxlength="50"show-word-limit style="width: 90%;"></el-input>
				</el-form-item>
				
      </el-form>
      
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
      <el-button @click="dialogVisible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      stringList: [
        {name: '校区', value: 1},
        {name: '学期', value: 2},
        {name: '一级课程', value: 3},
        {name: '二级课程', value: 4},
        {name: '学段', value: 5},
        {name: '学校', value: 6},
        {name: '年级', value: 7},
        {name: '班级', value: 8},
        {name: '退费原因', value: 3},

      ],//字段名列表
      projectList: [],//上级信息列表
      dataForm: {
        id: '',
        type: '',
        secType: '',
        optionValue: '',
		ename:''
      },
      dataRule: {
        type: [{required: true, message: '请选择字段名', trigger: 'change'}],
        secType: [{required: true, message: '请选择上级信息', trigger: 'change'}],
        optionValue: [{required: true, message: '请输入选项值', trigger: 'blur'}],
      }
    }
  },
  methods: {
    init (row) {
      this.dialogVisible = true
      this.$nextTick(()=>{
        this.$refs['dataForm'].resetFields();
        if (row.id) {
          this.dataForm.type = row.type
          this.dataForm.optionValue = row.name
          this.dataForm.id = row.id
		  this.dataForm.ename = row.ename
          if (row.classify == '一级课程') {
            this.dataForm.type = '二级课程'
            this.dataForm.secType = row.type
          }
        } else {
          this.dataForm.type = ''
          this.dataForm.secType = ''
          this.dataForm.optionValue = ''
          this.dataForm.id = ''
        }
      })
      this.getProjectList()
      
      
    },
    //获取一级课程下面的字典
    getProjectList () {
      var that = this
      that.$http({
        url: that.$http.adornUrl('/dict/list'),
        method: 'get',
        params: {type: '一级课程'}
      }).then(({
        data
      }) => {
        if (data.status) {
          that.projectList = data.data
        }
         
      })
    },
   
    dataFormSubmit () {
      var that = this
      var params = {
        classify: '活动',
        type: that.dataForm.type,
        name: that.dataForm.optionValue,
		ename:that.dataForm.ename
      }
      if (that.dataForm.id) {
        params.id = that.dataForm.id
      }
      if (!params.type) {
        this.$message.error('请选择字段名')
        return
      }
      if (!params.name) {
        this.$message.error('请输入选项值')
        return
      }
      if (params.type == '二级课程') {
        if (that.dataForm.secType) {
          params.type = that.dataForm.secType
          params.classify = '一级课程'
        } else {
          this.$message.error('请选择上级信息')
          return  
        }
      }
        that.$http({
          url: that.$http.adornUrl(that.dataForm.id ? '/dict/update' :'/dict/insert'),
          method: 'post',
          params: params
        }).then(({
          data
        }) => {
          if (data && data.status) {
          that.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              that.dialogVisible = false
              that.$emit('refreshData')
            }
          })
        } else {
          that.$message.error(data.msg)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  
</style>