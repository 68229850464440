<template>
  <div class="student-manage">
    <el-breadcrumb separator="/">
			<el-breadcrumb-item>字典表</el-breadcrumb-item>
		</el-breadcrumb>
    <div class="content">
      <div class="search-top">
        <div class="item">
          <el-input v-model="searchCon" type="text" placeholder="请输入字段名关键字查询" style="width: 400px;">
            <i slot="suffix" class="el-input__icon el-icon-search" style="font-size: 18px;" @click="getList"></i>
          </el-input>
        </div>
      </div>

      <div class="btn-group">
        <el-button type="primary" @click="handleShowAddOption('')" >添加</el-button>
        <el-button type="danger" :disabled="!dataListSelections.length"  @click="deleteHandle">删除</el-button>
      </div>

      <el-table :data="list" :header-cell-style="{background:'#FAFAFA'}" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="left" align="left" width="50" fixed="left">
			  </el-table-column>
        <el-table-column prop="type" header-align="left" align="left"  label="字段名">
			  </el-table-column>
        <el-table-column prop="name" header-align="left" align="left"  label="选项值">
			  </el-table-column>
        <el-table-column prop="classify" header-align="left" align="left"  label="上级信息">
			  </el-table-column>
        <el-table-column prop="libraryName" header-align="right" align="right"  label="操作" width="120" >
          <template slot-scope="scope">
            <el-button type="text" @click="handleShowAddOption(scope.row)" >编辑</el-button>
          </template>
			  </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <add-option ref="addOption" v-if="showAddOption" @refreshData="getList"></add-option>
  </div>
</template>

<script>
import AddOption from  './components/addOption'
export default {
  components: {
    AddOption
  },
  data () {
    return {
      searchCon: '',
      list: [],
      pageSize: 10,
      pageNo: 1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],//多选
      showAddOption: false,//显示弹窗
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      var that = this
      that.dataListLoading = true
      var params = {
        pageSize: that.pageSize,
        pageNo: that.pageNo,
        name: that.searchCon
      }
      that.$http({
        url: that.$http.adornUrl('/dict/getDictList'),
        method: 'get',
        params: params
      }).then(({
        data
      }) => {
        if (data.status) {
          that.list = data.data
          that.totalPage = data.totalCount
        } else {
          that.list = []
          that.totalPage = 0
        }
        that.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageNo = 1
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val
      this.getList()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    //显示弹窗
    handleShowAddOption (row) {
      this.showAddOption = true
      this.$nextTick(()=>{
        this.$refs.addOption.init(row)
      })
    },
    // 删除
    deleteHandle() {
      var ids = this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dict/delete'),
          method: 'post',
          params: {ids: ids.join(',')}
        }).then(({
          data
        }) => {
          if (data.status) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  },
}
</script>

<style lang="scss" scoped>
  .content{
    padding: 20px 0;
    .search-top{
      padding-bottom: 20px;
    }
    .btn-group{
      padding-bottom: 20px;
    }
  }
</style>